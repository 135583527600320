export interface ISensorValue {
    sensorId: string;
    userId: string;
    brandId: string;
    typeId: string;
    date: Date;
    value: number;
    units?: string;
}

export interface ISensorData {
    typeId: string
    lastValueDate: Date
}

export interface ISensorDataExtended extends ISensorData {
    lastValue: number
    units: string
}

export enum DeviceBrandId {
    //#region CHAMP
    WoePal = "9e62",
    WAQI = "8aaa",
    Flow = "105a",
    Fitbit = "1111",
    CapMedic = "tu76",
    //#endregion

    SakuraTech = "0ece",

    //#region A-IQ Ready
    inBiot = "0889",
    PolitoAndSAT = "ba11",
    //#endregion

    //#region SMILE
    AwarenessApp = "d521",
    DSS1 = "6be9",
    DSS2 = "4334",
    //#endregion

    //#region VETLLAR
    CEMIC = "720d",
    Fibaro = "8c81",
    AlertiT = "0995",
    Unknown = "e68b",
    //#endregion

    //#region AURA
    Sykno = "fa41",
    //#endregion

    //#region ICARE
    Anura = "d02b",
    //#endregion
}

export enum DeviceBrandName {
    //#region CHAMP
    WoePal = "WoePal",
    WAQI = "WAQI",
    Flow = "Flow",
    Fitbit = "Fitbit",
    CapMedic = "CapMedic",
    //#endregion

    SakuraTech = "NVISION",

    //#region A-IQ Ready
    inBiot = "inBiot",
    PolitoAndSAT = "Polito and SAT",
    //#endregion

    //#region SMILE
    AwarenessApp = "Awareness App",
    DSS1 = "DSS1",
    DSS2 = "DSS2",
    //#endregion

    //#region VETLLAR
    CEMIC = "CEMIC",
    Fibaro = "Fibaro",
    AlertiT = "Alert-iT",
    Unknown = "Unknown",
    //#endregion

    //#region AURA
    Sykno = "Sykno",
    //#endregion

    //#region ICARE
    Anura = "Anura",
    //#endregion
}

export enum SensorTypeId {
    HeartRate = "393a",
    BreathingRate = "344a",
    HRV = "884c",
    SPO2 = "04b8",
    PM1 = "0a51",
    PM10 = "9ab5",
    PM25 = "eacd",
    NO2 = "a372",
    VOC = "32c0",
    Pressure = "0a0e",
    Temperature = "d462",
    Humidity = "2e61",
    CO = "bfea",
    O3 = "a552",
    SO2 = "c951",
    PEF = "yo03",
    FEV1 = "g87i",
    PUFF = "p11d",
    AirQualityIndex = "f6e5",
    BreathingWave = "6853",
    CardiacWave = "4bcb",
    HeartbeatWave = "47f8",
    MovementWave = "9b85",
    CO2 = "0617",
    Formaldehyde = "7078",
    PM4 = "9e02",

    //#region A-IQ Ready
    Type = "5dc1",
    DrowsinessState = "2d83",
    Latitude = "1785",
    Longitude = "e7aa",
    Altitude = "7697",
    //#endregion

    //#region SMILE
    PHQ9Score = "c85b",
    GAD7Score = "36b6",
    AnxietyLevel = "2572",
    DepressionLevel = "e36d",
    //#endregion

    //#region VETLLAR
    FormaldehydeCounts = "92b4",
    FormaldehydeDiffCounts = "f33c",
    CO2Counts = "9e78",
    Door = "5520",
    SmartPlug = "6ec5",
    PressureMat = "bcc1",
    AmpereMeter = "f205",
    //#endregion

    //#region AURA
    Distance = "f47c",
    DistanceBreath = "8be6",
    DistancePulse = "e243",
    DistanceHeartSound = "c39f",
    //#endregion

    //#region ICARE
    ABSI = "73fa",
    AGE = "54bd",
    BMI_CALC = "8c1d",
    BP_CVD = "19e5",
    BP_DIASTOLIC = "37ac",
    BP_HEART_ATTACK = "47f3",
    BP_RPP = "2b4e",
    BP_STROKE = "68fd",
    BP_SYSTOLIC = "3c82",
    BP_TAU = "45d7",
    DBT_RISK_PROB = "8f62",
    FLD_RISK_PROB = "7d3e",
    HBA1C_RISK_PROB = "39ad",
    HDLTC_RISK_PROB = "92bf",
    HEIGHT = "1d74",
    HEALTH_SCORE = "67c9",
    HPT_RISK_PROB = "6aeb",
    IHB_COUNT = "3bef",
    MENTAL_SCORE = "4a9c",
    MFBG_RISK_PROB = "7c32",
    MSI = "6e1d",
    OVERALL_METABOLIC_RISK_PROB = "8f73",
    PHYSICAL_SCORE = "4ce7",
    PHYSIO_SCORE = "21d6",
    RISKS_SCORE = "5a93",
    SNR = "74bc",
    SURVEY_ANXIETY_MODERATE = "3df2",
    SURVEY_DEPRESSION_MODERATE = "6be4",
    TG_RISK_PROB = "91ab",
    VITAL_SCORE = "2e65",
    WAIST_CIRCUM = "5cb8",
    WAIST_TO_HEIGHT = "36d4",
    WEIGHT = "49f7"
    //#endregion
};

export enum SensorTypeName {
    HeartRate = "Heart rate",
    BreathingRate = "Breathing rate",
    HRV = "HRV",
    SPO2 = "SPO2",
    PM1 = "PM1",
    PM10 = "PM10",
    PM25 = "PM25",
    NO2 = "NO2",
    VOC = "VOC",
    Pressure = "Pressure",
    Temperature = "Temperature",
    Humidity = "Humidity",
    CO = "CO",
    O3 = "O3",
    SO2 = "SO2",
    PEF = "PEF",
    FEV1 = "FEV1",
    PUFF = "PUFF",
    AirQualityIndex = "AQI",
    BreathingWave = "Breathing wave",
    CardiacWave = "Cardiac wave",
    HeartbeatWave = "Heartbeat wave",
    MovementWave = "Movement wave",
    CO2 = "CO2",
    Formaldehyde = "Formaldehyde",
    PM4 = "PM4",

    //#region A-IQ Ready
    Type = "Type",
    DrowsinessState = "Drowsiness state",
    Latitude = "Latitude",
    Longitude = "Longitude",
    Altitude = "Altitude",
    //#endregion

    //#region SMILE
    PHQ9Score = "PHQ-9 score",
    GAD7Score = "GAD-7 score",
    AnxietyLevel = "Anxiety level",
    DepressionLevel = "Depression level",
    //#endregion

    //#region VETLLAR
    FormaldehydeCounts = "Formaldehyde counts",
    FormaldehydeDiffCounts = "Formaldehyde diff. counts",
    CO2Counts = "CO2 counts",
    Door = "Door",
    SmartPlug = "Smart plug",
    PressureMat = "Pressure mat",
    AmpereMeter = "Ampere meter",
    //#endregion

    //#region AURA
    Distance = "Distance",
    DistanceBreath = "Distance breath",
    DistancePulse = "Distance pulse",
    DistanceHeartSound = "Distance heart sound",
    //#endregion

    //#region ICARE
    ABSI = "Body shape index",
    AGE = "Facial skin age",
    BMI_CALC = "Body mass index",
    BP_CVD = "Cardiovascular disease risk",
    BP_DIASTOLIC = "Diastolic blood pressure",
    BP_HEART_ATTACK = "Heart attack risk",
    BP_RPP = "Cardiac workload",
    BP_STROKE = "Stroke risk",
    BP_SYSTOLIC = "Systolic blood pressure",
    BP_TAU = "Vascular capacity",
    DBT_RISK_PROB = "Type 2 diabetes risk",
    FLD_RISK_PROB = "Fatty liver disease risk",
    HBA1C_RISK_PROB = "Hemoglobin A1C risk",
    HDLTC_RISK_PROB = "Hypercholesterolemia risk",
    HEIGHT = "Estimated height",
    HEALTH_SCORE = "NuraLogix general wellness score",
    HPT_RISK_PROB = "Hypertension risk",
    IHB_COUNT = "Irregular heartbeat count",
    MENTAL_SCORE = "NuraLogix mental score",
    MFBG_RISK_PROB = "Fasting blood glucose risk",
    MSI = "NuraLogix mental stress index",
    OVERALL_METABOLIC_RISK_PROB = "Overall metabolic health risk",
    PHYSICAL_SCORE = "NuraLogix physical score",
    PHYSIO_SCORE = "NuraLogix physiological score",
    RISKS_SCORE = "NuraLogix risks score",
    SNR = "Signal to noise ratio",
    SURVEY_ANXIETY_MODERATE = "Moderate anxiety rapid assessment",
    SURVEY_DEPRESSION_MODERATE = "Moderate depression rapid assessment",
    TG_RISK_PROB = "Hypertriglyceridemia risk",
    VITAL_SCORE = "NuraLogix vitals score",
    WAIST_CIRCUM = "Waist circumference",
    WAIST_TO_HEIGHT = "Waist-to-height ratio",
    WEIGHT = "Estimated weight"
    //#endregion
};