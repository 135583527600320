import { DeviceBrandId, DeviceBrandName, SensorTypeId, SensorTypeName } from "models/ISensor";

export const getDeviceBrandName: (deviceBrandId: string) => string = (deviceBrandId: string) => {
    switch (deviceBrandId) {
        case DeviceBrandId.WoePal:
            return DeviceBrandName.WoePal;
        case DeviceBrandId.WAQI:
            return DeviceBrandName.WAQI;
        case DeviceBrandId.Flow:
            return DeviceBrandName.Flow;
        case DeviceBrandId.Fitbit:
            return DeviceBrandName.Fitbit;
        case DeviceBrandId.CapMedic:
            return DeviceBrandName.CapMedic;
        case DeviceBrandId.SakuraTech:
            return DeviceBrandName.SakuraTech;
        case DeviceBrandId.inBiot:
            return DeviceBrandName.inBiot;
        case DeviceBrandId.AwarenessApp:
            return DeviceBrandName.AwarenessApp;
        case DeviceBrandId.DSS2:
            return DeviceBrandName.DSS2;
        case DeviceBrandId.CEMIC:
            return DeviceBrandName.CEMIC;
        case DeviceBrandId.PolitoAndSAT:
            return DeviceBrandName.PolitoAndSAT;
        case DeviceBrandId.Sykno:
            return DeviceBrandName.Sykno;
        case DeviceBrandId.Fibaro:
            return DeviceBrandName.Fibaro;
        case DeviceBrandId.AlertiT:
            return DeviceBrandName.AlertiT;
        case DeviceBrandId.Unknown:
            return DeviceBrandName.Unknown;
        case DeviceBrandId.Anura:
            return DeviceBrandName.Anura;
        default:
            return "Unknown";
    }
};

export const getSensorTypeName: (sensorTypeId: string) => string = (sensorTypeId: string) => {
    switch (sensorTypeId) {
        case SensorTypeId.HeartRate:
            return SensorTypeName.HeartRate;
        case SensorTypeId.BreathingRate:
            return SensorTypeName.BreathingRate;
        case SensorTypeId.HRV:
            return SensorTypeName.HRV;
        case SensorTypeId.SPO2:
            return SensorTypeName.SPO2;
        case SensorTypeId.PM1:
            return SensorTypeName.PM1;
        case SensorTypeId.PM10:
            return SensorTypeName.PM10;
        case SensorTypeId.PM25:
            return SensorTypeName.PM25;
        case SensorTypeId.NO2:
            return SensorTypeName.NO2;
        case SensorTypeId.VOC:
            return SensorTypeName.VOC;
        case SensorTypeId.Pressure:
            return SensorTypeName.Pressure;
        case SensorTypeId.Temperature:
            return SensorTypeName.Temperature;
        case SensorTypeId.Humidity:
            return SensorTypeName.Humidity;
        case SensorTypeId.CO:
            return SensorTypeName.CO;
        case SensorTypeId.O3:
            return SensorTypeName.O3;
        case SensorTypeId.SO2:
            return SensorTypeName.SO2;
        case SensorTypeId.PEF:
            return SensorTypeName.PEF;
        case SensorTypeId.FEV1:
            return SensorTypeName.FEV1;
        case SensorTypeId.PUFF:
            return SensorTypeName.PUFF;
        case SensorTypeId.AirQualityIndex:
            return SensorTypeName.AirQualityIndex;
        case SensorTypeId.BreathingWave:
            return SensorTypeName.BreathingWave;
        case SensorTypeId.CardiacWave:
            return SensorTypeName.CardiacWave;
        case SensorTypeId.HeartbeatWave:
            return SensorTypeName.HeartbeatWave;
        case SensorTypeId.MovementWave:
            return SensorTypeName.MovementWave;
        case SensorTypeId.CO2:
            return SensorTypeName.CO2;
        case SensorTypeId.Formaldehyde:
            return SensorTypeName.Formaldehyde;
        case SensorTypeId.PM4:
            return SensorTypeName.PM4;
        case SensorTypeId.PHQ9Score:
            return SensorTypeName.PHQ9Score;
        case SensorTypeId.GAD7Score:
            return SensorTypeName.GAD7Score;
        case SensorTypeId.AnxietyLevel:
            return SensorTypeName.AnxietyLevel;
        case SensorTypeId.DepressionLevel:
            return SensorTypeName.DepressionLevel;
        case SensorTypeId.FormaldehydeCounts:
            return SensorTypeName.FormaldehydeCounts;
        case SensorTypeId.FormaldehydeDiffCounts:
            return SensorTypeName.FormaldehydeDiffCounts;
        case SensorTypeId.CO2Counts:
            return SensorTypeName.CO2Counts;
        case SensorTypeId.Type:
            return SensorTypeName.Type;
        case SensorTypeId.DrowsinessState:
            return SensorTypeName.DrowsinessState;
        case SensorTypeId.Latitude:
            return SensorTypeName.Latitude;
        case SensorTypeId.Longitude:
            return SensorTypeName.Longitude;
        case SensorTypeId.Altitude:
            return SensorTypeName.Altitude;
        case SensorTypeId.Door:
            return SensorTypeName.Door;
        case SensorTypeId.SmartPlug:
            return SensorTypeName.SmartPlug;
        case SensorTypeId.PressureMat:
            return SensorTypeName.PressureMat;
        case SensorTypeId.AmpereMeter:
            return SensorTypeName.AmpereMeter;
        case SensorTypeId.Distance:
            return SensorTypeName.Distance;
        case SensorTypeId.DistanceBreath:
            return SensorTypeName.DistanceBreath;
        case SensorTypeId.DistancePulse:
            return SensorTypeName.DistancePulse;
        case SensorTypeId.DistanceHeartSound:
            return SensorTypeName.DistanceHeartSound;
        case SensorTypeId.ABSI:
            return SensorTypeName.ABSI;
        case SensorTypeId.AGE:
            return SensorTypeName.AGE;
        case SensorTypeId.BMI_CALC:
            return SensorTypeName.BMI_CALC;
        case SensorTypeId.BP_CVD:
            return SensorTypeName.BP_CVD;
        case SensorTypeId.BP_DIASTOLIC:
            return SensorTypeName.BP_DIASTOLIC;
        case SensorTypeId.BP_HEART_ATTACK:
            return SensorTypeName.BP_HEART_ATTACK;
        case SensorTypeId.BP_RPP:
            return SensorTypeName.BP_RPP;
        case SensorTypeId.BP_STROKE:
            return SensorTypeName.BP_STROKE;
        case SensorTypeId.BP_SYSTOLIC:
            return SensorTypeName.BP_SYSTOLIC;
        case SensorTypeId.BP_TAU:
            return SensorTypeName.BP_TAU;
        case SensorTypeId.DBT_RISK_PROB:
            return SensorTypeName.DBT_RISK_PROB;
        case SensorTypeId.FLD_RISK_PROB:
            return SensorTypeName.FLD_RISK_PROB;
        case SensorTypeId.HBA1C_RISK_PROB:
            return SensorTypeName.HBA1C_RISK_PROB;
        case SensorTypeId.HDLTC_RISK_PROB:
            return SensorTypeName.HDLTC_RISK_PROB;
        case SensorTypeId.HEIGHT:
            return SensorTypeName.HEIGHT;
        case SensorTypeId.HEALTH_SCORE:
            return SensorTypeName.HEALTH_SCORE;
        case SensorTypeId.HPT_RISK_PROB:
            return SensorTypeName.HPT_RISK_PROB;
        case SensorTypeId.IHB_COUNT:
            return SensorTypeName.IHB_COUNT;
        case SensorTypeId.MENTAL_SCORE:
            return SensorTypeName.MENTAL_SCORE;
        case SensorTypeId.MFBG_RISK_PROB:
            return SensorTypeName.MFBG_RISK_PROB;
        case SensorTypeId.MSI:
            return SensorTypeName.MSI;
        case SensorTypeId.OVERALL_METABOLIC_RISK_PROB:
            return SensorTypeName.OVERALL_METABOLIC_RISK_PROB;
        case SensorTypeId.PHYSICAL_SCORE:
            return SensorTypeName.PHYSICAL_SCORE;
        case SensorTypeId.PHYSIO_SCORE:
            return SensorTypeName.PHYSIO_SCORE;
        case SensorTypeId.RISKS_SCORE:
            return SensorTypeName.RISKS_SCORE;
        case SensorTypeId.SNR:
            return SensorTypeName.SNR;
        case SensorTypeId.SURVEY_ANXIETY_MODERATE:
            return SensorTypeName.SURVEY_ANXIETY_MODERATE;
        case SensorTypeId.SURVEY_DEPRESSION_MODERATE:
            return SensorTypeName.SURVEY_DEPRESSION_MODERATE;
        case SensorTypeId.TG_RISK_PROB:
            return SensorTypeName.TG_RISK_PROB;
        case SensorTypeId.VITAL_SCORE:
            return SensorTypeName.VITAL_SCORE;
        case SensorTypeId.WAIST_CIRCUM:
            return SensorTypeName.WAIST_CIRCUM;
        case SensorTypeId.WAIST_TO_HEIGHT:
            return SensorTypeName.WAIST_TO_HEIGHT;
        case SensorTypeId.WEIGHT:
            return SensorTypeName.WEIGHT;
        default:
            return "Unknown";
    }
};