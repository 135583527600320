import { UserProviderId } from "models/IUser";

export const baseUrl: string = process.env.REACT_APP_API_BASE_URL ?? '';
export const authHeaderName: string = "Authorization";

export const appsettings: any = {
    clients: {
      "champ.excare.io": {
        title: "CHAMP asthma & pollution",
        provider: UserProviderId.CHAMP,
        backgroundLoginLeft: "<svg xmlns='http://www.w3.org/2000/svg'  width='20' height='20' viewBox='0 0 100 100'><rect fill='#0C5B97' width='100' height='100'/><rect x='0' y='0' width='52' height='52'  fill-opacity='0.8' fill='#FFFFFF'/></svg>",
        logo: "champ_logo.png",
        icon: "champ_icon.png",
        favicon: "champ_favicon.ico",
        description: "",
        features: []
      },
      "kore.excare.io": {
        title: "KORE contactless patient monitoring",
        provider: UserProviderId.KORE,
        backgroundLoginLeft: "<svg xmlns='http://www.w3.org/2000/svg'  width='20' height='20' viewBox='0 0 100 100'><rect fill='#0b3d80' width='100' height='100'/><rect x='0' y='0' width='52' height='52'  fill-opacity='0.8' fill='#FFFFFF'/></svg>",
        logo: "kore_logo.png",
        icon: "kore_icon.png",
        favicon: "kore_favicon.ico",
        description: "",
        features: []
      },
      "aiqready.excare.io": {
        title: "Artificial Intelligence for Realtime Distributed Systems at the Edge",
        provider: UserProviderId.AIQReady,
        backgroundLoginLeft: "<svg xmlns='http://www.w3.org/2000/svg'  width='20' height='20' viewBox='0 0 100 100'><rect fill='#152c55' width='100' height='100'/><rect x='0' y='0' width='52' height='52'  fill-opacity='0.8' fill='#FFFFFF'/></svg>",
        logo: "aiqready_logo.png",
        icon: "aiqready_icon.png",
        favicon: "aiqready_favicon.ico",
        description: "",
        features: []
      },
      "smile.excare.io": {
        title: "SMILE – Supporting Mental Health in Young People",
        provider: UserProviderId.SMILE,
        backgroundLoginLeft: "<svg xmlns='http://www.w3.org/2000/svg'  width='20' height='20' viewBox='0 0 100 100'><rect fill='#ff6faa' width='100' height='100'/><rect x='0' y='0' width='52' height='52'  fill-opacity='0.8' fill='#FFFFFF'/></svg>",
        logo: "smile_logo.png",
        icon: "smile_icon.png",
        favicon: "smile_favicon.ico",
        description: "",
        features: []
      },
      "vetllar.encontrol.io": {
        title: "VETLLAR",
        provider: UserProviderId.VETLLAR,
        backgroundLoginLeft: "<svg xmlns='http://www.w3.org/2000/svg'  width='20' height='20' viewBox='0 0 100 100'><rect fill='#8ea0b3' width='100' height='100'/><rect x='0' y='0' width='52' height='52'  fill-opacity='0.8' fill='#FFFFFF'/></svg>",
        logo: "vetllar_logo.png",
        icon: "vetllar_icon.png",
        favicon: "vetllar_favicon.ico",
        description: "",
        features: []
      },
      "aura.excare.io": {
        title: "AURA",
        provider: UserProviderId.AURA,
        backgroundLoginLeft: "<svg xmlns='http://www.w3.org/2000/svg'  width='20' height='20' viewBox='0 0 100 100'><rect fill='#000537' width='100' height='100'/><rect x='0' y='0' width='52' height='52'  fill-opacity='0.8' fill='#FFFFFF'/></svg>",
        logo: "aura_logo.png",
        icon: "aura_icon.png",
        favicon: "aura_favicon.ico",
        description: "",
        features: []
      },
      "icare.excare.io": {
        title: "ICARE",
        provider: UserProviderId.ICARE,
        backgroundLoginLeft: "<svg xmlns='http://www.w3.org/2000/svg'  width='20' height='20' viewBox='0 0 100 100'><rect fill='#ed7d31' width='100' height='100'/><rect x='0' y='0' width='52' height='52'  fill-opacity='0.8' fill='#FFFFFF'/></svg>",
        logo: "icare_logo.png",
        icon: "icare_icon.png",
        favicon: "icare_favicon.ico",
        description: "",
        features: []
      },
      default: "icare.excare.io",
    }
}