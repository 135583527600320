import { DeviceBrandName, SensorTypeName, DeviceBrandId, SensorTypeId } from "models/ISensor";

export const ICARETelemetries = {
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.ABSI}`]: `${DeviceBrandId.Anura}:${SensorTypeId.ABSI}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.AGE}`]: `${DeviceBrandId.Anura}:${SensorTypeId.AGE}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.BMI_CALC}`]: `${DeviceBrandId.Anura}:${SensorTypeId.BMI_CALC}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.BP_CVD}`]: `${DeviceBrandId.Anura}:${SensorTypeId.BP_CVD}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.BP_DIASTOLIC}`]: `${DeviceBrandId.Anura}:${SensorTypeId.BP_DIASTOLIC}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.BP_HEART_ATTACK}`]: `${DeviceBrandId.Anura}:${SensorTypeId.BP_HEART_ATTACK}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.BP_RPP}`]: `${DeviceBrandId.Anura}:${SensorTypeId.BP_RPP}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.BP_STROKE}`]: `${DeviceBrandId.Anura}:${SensorTypeId.BP_STROKE}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.BP_SYSTOLIC}`]: `${DeviceBrandId.Anura}:${SensorTypeId.BP_SYSTOLIC}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.BP_TAU}`]: `${DeviceBrandId.Anura}:${SensorTypeId.BP_TAU}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.DBT_RISK_PROB}`]: `${DeviceBrandId.Anura}:${SensorTypeId.DBT_RISK_PROB}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.FLD_RISK_PROB}`]: `${DeviceBrandId.Anura}:${SensorTypeId.FLD_RISK_PROB}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.HBA1C_RISK_PROB}`]: `${DeviceBrandId.Anura}:${SensorTypeId.HBA1C_RISK_PROB}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.HDLTC_RISK_PROB}`]: `${DeviceBrandId.Anura}:${SensorTypeId.HDLTC_RISK_PROB}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.HEIGHT}`]: `${DeviceBrandId.Anura}:${SensorTypeId.HEIGHT}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.HEALTH_SCORE}`]: `${DeviceBrandId.Anura}:${SensorTypeId.HEALTH_SCORE}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.HPT_RISK_PROB}`]: `${DeviceBrandId.Anura}:${SensorTypeId.HPT_RISK_PROB}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.IHB_COUNT}`]: `${DeviceBrandId.Anura}:${SensorTypeId.IHB_COUNT}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.MENTAL_SCORE}`]: `${DeviceBrandId.Anura}:${SensorTypeId.MENTAL_SCORE}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.MFBG_RISK_PROB}`]: `${DeviceBrandId.Anura}:${SensorTypeId.MFBG_RISK_PROB}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.MSI}`]: `${DeviceBrandId.Anura}:${SensorTypeId.MSI}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.OVERALL_METABOLIC_RISK_PROB}`]: `${DeviceBrandId.Anura}:${SensorTypeId.OVERALL_METABOLIC_RISK_PROB}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.PHYSICAL_SCORE}`]: `${DeviceBrandId.Anura}:${SensorTypeId.PHYSICAL_SCORE}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.PHYSIO_SCORE}`]: `${DeviceBrandId.Anura}:${SensorTypeId.PHYSIO_SCORE}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.RISKS_SCORE}`]: `${DeviceBrandId.Anura}:${SensorTypeId.RISKS_SCORE}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.SNR}`]: `${DeviceBrandId.Anura}:${SensorTypeId.SNR}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.SURVEY_ANXIETY_MODERATE}`]: `${DeviceBrandId.Anura}:${SensorTypeId.SURVEY_ANXIETY_MODERATE}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.SURVEY_DEPRESSION_MODERATE}`]: `${DeviceBrandId.Anura}:${SensorTypeId.SURVEY_DEPRESSION_MODERATE}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.TG_RISK_PROB}`]: `${DeviceBrandId.Anura}:${SensorTypeId.TG_RISK_PROB}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.VITAL_SCORE}`]: `${DeviceBrandId.Anura}:${SensorTypeId.VITAL_SCORE}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.WAIST_CIRCUM}`]: `${DeviceBrandId.Anura}:${SensorTypeId.WAIST_CIRCUM}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.WAIST_TO_HEIGHT}`]: `${DeviceBrandId.Anura}:${SensorTypeId.WAIST_TO_HEIGHT}`,
        [`[${DeviceBrandName.Anura}] ${SensorTypeName.WEIGHT}`]: `${DeviceBrandId.Anura}:${SensorTypeId.WEIGHT}`
};